<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm1">
          <c-card class="cardClassDetailForm" title="오더내역">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  ref="appr-btn"
                  name="equipment-daily-appr-btn"
                  apprRequestTitle="작업결과 승인요청"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <!-- <c-btn label="삭제" v-if="editable && param.workPlanId && param.woWorkPlanStepCd=='WWPSC99998' && !disabled" icon="delete_forever" @btnClicked="removePlan" /> -->
                <c-btn
                  v-if="editable &&!disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="정비오더No."
                  name="workOrderNo"
                  v-model="data.workOrderNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled"
                  label="정비오더명"
                  name="workResultName"
                  v-model="data.workResultName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  :required="true"
                  :editable="false"
                  codeGroupCd="WO_WORK_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="woWorkTypeCd"
                  label="작업분류"
                  v-model="data.woWorkTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  :required="true"
                  :editable="false"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-equip
                  :required="true"
                  :editable="false"
                  label="설비명"
                  name="equipmentCd"
                  v-model="data.equipmentCd">
                </c-equip>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-location 
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :editable="editable&&!disabled" 
                  :data="data"
                  etcInput="locationEtc"
                  label="장소" 
                  name="locationCd" 
                  v-model="data.locationCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept type="edit" :required="true" :editable="false" label="정비오더 작업부서" name="workDeptCd" v-model="data.workDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-field
                  :required="true"
                  :editable="false"
                  :data="data"
                  type="user"
                  label="작업계획자"
                  name="planUserId"
                  v-model="data.planUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :required="true"
                  :range="true"
                  :editable="editable&&!disabled"
                  type="date"
                  label="작업계획기간"
                  name="planDts"
                  v-model="data.planDts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :required="true"
                  :range="true"
                  :editable="editable&&!disabled"
                  type="date"
                  label="실제작업기간"
                  name="resultDts"
                  v-model="data.resultDts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable&&!disabled"
                  :rows="8"
                  label="정비결과 세부사항"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-plan-direction',
  props: {
    param: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        workResultId: '',
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      selectedWork: false,
      workerSwss: [],
      workerSwssInfo: '',
      editable: true,
      detailUrl: '',
      updateUrl: '',
      equiplistUrl: '',
      insertequipUrl: '',
      removeequipUrl: '',
      costlistUrl: '',
      insertcostUrl: '',
      removecostUrl: '',
      worklistUrl: '',
      workgetUrl: '',
      saveWorkUrl: transactionConfig.wod.workorder.result.work.insert.url,
      removeworkUrl: '',
      isApproval: false,
      saveUrl: transactionConfig.wod.workorder.result.update.url,
      mappingType: 'PUT',
      mappingType2: 'PUT',
      workUpdateMode: false,
      isSave: false,
      isSave2: false,
      selectedWorkResultWorkId: '',
      data: {
        plantCd: null,  // 사업장코드
        preWorkPlanId: '',  // 작업계획 일련번호
        workPlanId: '',  // 작업계획 일련번호
        workResultId: '',  // 작업결과 일련번호
        workOrderNo: '',  // 작업지시번호
        workResultName: '',  // 작업명
        workDeptCd: '',  // 작업부서
        planUserId: '',  // 작업지시자
        woRequestId: '',  // 정비요청 일련번호
        reqDeptCd: '',  // 요청부서
        reqUserId: '',  // 요청인
        reqDt: '',  // 요청서 발행일
        woWorkTypeCd: null,  // 작업 유형코드
        equipmentCd: '',  // 대상 설비
        funcLocationCd: '',  // 기능위치
        locationEtc: '',  // 기능위치
        remark: '',  // 정비요청 세부사항
        swpFlag: 'N',  // 안전작업허가서 발행여부
        woWorkPlanStepCd: '',  // 작업진행 상태
        preWoWorkPlanStepCd: '',
        planDt: '',
        planDts: [],
        planStartDt: '',  // 목표 시작일
        planStartTime: '',  // 목표 시작시간
        planStartDts: '',
        planEndDt: '',  // 목표 완료일
        planEndTime: '',  // 목표 종료시간
        planEndDts: '',
        resultDts: [],
        resultStartDt: '',  // 목표 시작일
        resultEndDt: '',  // 목표 완료일
        chgUserName: '',
        chgDtStr: '',
        costList: [],
      },
      wopdata: {
        sortOrder: '',
        workOprTypeCd: null,
        workDts: [],
        contents: '',
        workpermitCds: [],
        workRiskFactors: [],
        vendorCd: '',
        workUsers: [],
        workerCnt: '',
        permitNo: '',
      },
      workdata: {
        workResultId: '',  // 정비오더 일련번호
        workResultWorkId: '',  // 작업계획 일련번호
        workResultWorkName: '',  // 작업내용
        sortOrder: '',  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        workDeptCd: '',  // 작업부서
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        spareList: [],
        deleteSpareList: [],
        workerList: [],
        deleteWorkerList: [],
        workHolidayFlag: 'N',
      },
      grid2: {
        columns: [],
        data: [],
        height: '200px',
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WORK_ORDER_SERVICE_RESULT',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    userInfo() {
      return {
        userItems: this.wopdata.workUsers,
        userText: 'workUserName',
        userValue: 'workUserId',
        deptText: 'workDeptName',
        deptValue: 'workDeptCd',
      }
    },
    disabled() {
      this.$emit('disabledSet', this.disabledObj.disabled)
      return this.disabledObj.disabled;
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.preExistDisabled && this.data.woWorkPlanStepCd && this.data.woWorkPlanStepCd !== 'WWPSC00009' && this.data.woWorkPlanStepCd !== 'WWPSC99999' && this.data.woWorkPlanStepCd !== 'WWPSC99998' && !this.disabledAppr, // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000022', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          workPlanId: this.param.workPlanId
        },
        approvalRequestName: '작업결과 ['+this.data.workResultName+']', // 결재요청명 (문서 title)
        approvalConnId: this.param.workPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    // [E] 결재관련 버튼 컨트롤
    preExistDisabled() {
      // if (!this.data.preWoWorkPlanStepCd) {
      //   return true;
      // } else {
      //   return this.data.preWoWorkPlanStepCd == 'WWPSC00009';
      // }
      return true;
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.wod.workorder.result.get.url;
      this.updateUrl = transactionConfig.wod.workorder.result.update.url;
      this.equiplistUrl = selectConfig.wod.workorder.result.equip.url;
      this.insertequipUrl = transactionConfig.wod.workorder.result.equip.insert.url;
      this.removeequipUrl = transactionConfig.wod.workorder.result.equip.delete.url;
      this.costlistUrl = selectConfig.wod.workorder.result.cost.url;
      this.insertcostUrl = transactionConfig.wod.workorder.result.cost.save.url;
      this.removecostUrl = transactionConfig.wod.workorder.result.cost.delete.url;
      
      this.worklistUrl = selectConfig.wod.workorder.result.work.list.url;
      this.workgetUrl = selectConfig.wod.workorder.result.work.get.url;
      this.saveworkUrl = transactionConfig.wod.workorder.result.work.insert.url;
      this.removeworkUrl = transactionConfig.wod.workorder.result.work.delete.url;

      if (this.param.woWorkPlanStepCd !== 'WWPSC00003') {
        this.disabledObj.disabled = true;
      }
      this.getDetail()
    },
    getDetail() {
      if (this.param.workPlanId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {workPlanId: this.param.workPlanId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (this.data.woWorkPlanStepCd !== 'WWPSC00003') {
            this.disabledObj.disabled = true;
          } else {
            this.disabledObj.disabled = false;
          }
        },);
      } else {
        this.data.planUserId = this.$store.getters.user.userId;

        if (this.param.newEquipmentCd) {
          this.data.equipmentCd = this.param.newEquipmentCd;
          this.data.plantCd = this.param.plantCd;
          this.data.woWorkTypeCd = this.param.newWoWorkTypeCd;
          this.data.workResultnName = this.param.newTitle;
        }
      }
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveDataAppr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              let promises = [
                {
                  func: this.approvalValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    saveData() {
      if (this.param.workPlanId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      }
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>